<template>
  <div class="login">
    <div v-if="isAuthenticated === false" class="columns">
      <div
        class="column is-6-desktop is-offset-3-desktop is-8-tablet is-offset-2-tablet"
      >
        <div class="box">
          <LoginForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from "@/components/LoginForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "login",
  components: {
    LoginForm,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      loginError: "auth/loginError",
    }),
  },
  created: function () {
    // this.$store.dispatch("setCurrentView", "Login");
  },
};
</script>
