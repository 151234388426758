<template>
  <section class="section">
    <v-container>
      <v-form>
        <v-card class="mx-auto" max-width="400">
          <v-card-title>Anmelden</v-card-title>
          <v-card-text>
            <v-row>
              <v-text-field
                v-model="email"
                prepend-icon="email"
                label="E-Mail"
                type="email"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="password"
                prepend-icon="lock"
                label="Password"
                type="password"
              ></v-text-field>
            </v-row>
            <v-alert v-if="loginError !== ''" type="error">
              {{ loginError }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="submit()">Anmelden</v-btn>
            <!-- <v-btn @click="logout()">logout</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Signin",
  data() {
    return {
      valid: true,
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      loginError: "auth/loginError",
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    submit() {
      if (this.validateForm()) {
        this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
        });
      }
    },

    validateForm() {
      if (this.email !== "" && this.password !== "") {
        this.valid = true;
        return true;
      }
      this.valid = false;
      return false;
    },
  },
};
</script>
